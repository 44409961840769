
import { requests } from "@/helpers/requests";
import { FaqResponse } from "@/responses/faq";
import { Options, Vue } from "vue-class-component";

@Options({
  name: "FAQ",
  created() {
    this.fetchFaq();
  },
  watch: {
    "$i18n.locale": function () {
      this.fetchFaq();
    },
  },
  data() {
    return {
      faqs: [],
    };
  },
  methods: {
    fetchFaq() {
      requests
        .get("faqs/list?lang=" + this.$i18n.locale)
        .then((response: FaqResponse) => {
          if (response.success && response.data) {
            this.faqs = response.data.slice(0, 5);
          }
        });
    },
  },
})
export default class FAQ extends Vue {}
