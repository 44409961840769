import { createStore } from "vuex";
import { CartRow } from "@/models/cart";
import { Product } from "@/models/product";

export default createStore({
  state: {
    cart: {
      total: 0.0,
    },
    blockedDates: [],
    menus: {},
    translations: {},
    token: localStorage.getItem('token'),
    userFirstName: localStorage.getItem('userFirstName')
  },
  getters: {},
  mutations: {
    setCart(state, cart) {
      cart.rows.forEach((row: CartRow) => {
        row.info.productName = Product.translateName(
          row.info.productName,
          state.translations
        );
      });
      state.cart = cart;
    },
    setBlockedDates(state, blockedDates) {
      state.blockedDates = blockedDates;
    },
    setMenus(state, menus) {
      state.menus = menus;
    },
    setTranslations(state, translations) {
      state.translations = translations;
    },
    setToken(state, token) {
      state.token = token;
    },
    setUserFirstName(state, firstName) {
      state.userFirstName = firstName;
    }
  },
  actions: {},
  modules: {},
});
