import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/left.png'
import _imports_1 from '@/assets/images/right.png'


const _withScopeId = (n: any) => (_pushScopeId("data-v-7cf41796"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "testimonial" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-12" }
const _hoisted_5 = {
  class: "bbb_slider_container1",
  ref: "carousel"
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-lg-8 d-flex flex-column justify-content-center" }
const _hoisted_8 = { style: {"color":"#807e7e"} }
const _hoisted_9 = { class: "col-lg-4" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "col-lg-12 d-flex justify-content-end align-items-end" }
const _hoisted_12 = { class: "blogSliderNavigation" }
const _hoisted_13 = { class: "sliderNavLeft" }
const _hoisted_14 = { class: "sliderNavRight" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: _normalizeClass(['owl-carousel owl-theme bbb_slider1', 'page-' + _ctx.page]),
              style: _normalizeStyle(
                _ctx.carouselWidth != null ? 'width: ' + _ctx.carouselWidth + 'px' : ''
              )
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.testimonials, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "owl-item w-100",
                  key: item.name
                }, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("p", null, _toDisplayString(item.text), 1),
                      _createElementVNode("span", null, _toDisplayString(item.name), 1),
                      _createElementVNode("p", _hoisted_8, _toDisplayString(item.subText), 1)
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("img", {
                        src: item.image,
                        alt: "",
                        class: "w-100"
                      }, null, 8, _hoisted_10)
                    ])
                  ])
                ]))
              }), 128))
            ], 6)
          ], 512)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("img", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.move(-1))),
                src: _imports_0,
                alt: ""
              })
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("img", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.move(1))),
                src: _imports_1,
                alt: ""
              })
            ])
          ])
        ])
      ])
    ])
  ]))
}