
import { Options, Vue } from "vue-class-component";
import { requests } from "@/helpers/requests";
import ProductItem from "@/components/Product/ProductItem.vue";

@Options({
  name: "ProductList",
  components: {
    ProductItem,
  },
  props: {
    relatedTo: String,
    favourites: Boolean,
  },
  data() {
    return {
      products: [],
    };
  },
  created() {
    let url = "";
    if (this.favourites) {
      url = "products/favourites";
    } else if (this.relatedTo) {
      url = "products/related/" + this.relatedTo;
    }
    requests.get(url).then((response) => {
      if (response.success) {
        this.products = response.products;
      }
    });
  },
})
export default class ProductList extends Vue {}
