import axios from 'axios';

export const requests = {
  get,
  post,
  handleResponse,
  postRequest,
};

// const apiUrl = "https://dutchflowers.com";
const apiUrl = "/api";

function get(url: string, headers = {}) {
  const requestOptions: RequestInit = {
    method: "GET",
    credentials: "include",
    headers: { "Content-Type": "application/json", ...headers }
  };

  return fetch(`${apiUrl}/${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function post(url: string, payload: object, headers = {}) {
  return dataRequest(url, payload, "POST", headers);
}

function dataRequest(url: string, payload: object, method: string, headers: object) {
  const requestOptions = {
    method: method,
    headers: { "Content-Type": "application/json", ...headers },
    body: JSON.stringify(payload),
  };

  return fetch(`${apiUrl}/${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function handleResponse(response: Response) {
  return response.text().then((text: string) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        //Todo Show unauthorizes snackbar
      }
      if (response.status === 409) {
        return Promise.reject(data);
      }
      return Promise.reject(data);
    }

    return data;
  });
}

function postRequest (url: string, payload: object, headers: object) {
  return axios.post(`${apiUrl}/${url}`, payload, headers)
    .then(res => {
      return res.data;
    })
    .catch(e => {
      return handleHttpError(e);
    });
}

function handleHttpError (e: any) {
  if (e.response && e.response.data && e.response.data.message)
    return { error: true, msg: e.response.data.message, status: e.response.status, ...e.response.data };
  // not sure if some of the error response.data have message instead of errors parameter
  // errors will always be in "errors" (plural, my bad for some that were in "error")
  if (e.response && e.response.data && e.response.data.errors)
    return { error: true, msg: e.response.data.errors, status: e.response.status, ...e.response.data };

  if(e.response)
    return { error: true, msg: 'Something went wrong!', status: e.response.status, ...e.response.data };

  return { error: true, msg: 'Something went wrong!', status: 503 };
}
