
import { Options, Vue } from "vue-class-component";
import { requests } from "@/helpers/requests";
import { CartAddResponse } from "@/responses/cart-add";
import { Product } from "@/models/product";

@Options({
  name: "ProductItem",
  props: {
    product: Object,
    height: {
      type: String,
      default: "277px",
    },
  },
  created() {
    this.getLength();
    this.product.productName = Product.translateName(
      this.product.productName,
      this.$store.state.translations
    );
  },
  data() {
    return {
      showPanel: false,
      amount: 1,
      length: null,
    };
  },
  methods: {
    showPops() {
      this.showPanel = !this.showPanel;
    },
    addToCart(event: MouseEvent) {
      event.stopImmediatePropagation();
      event.preventDefault();
      requests
        .post("cart/add", {
          id: this.product.productId,
          amount: this.amount,
        })
        .then((response: CartAddResponse) => {
          this.$store.commit("setCart", response.cart);
          this.productAdded = true;
        });
    },
    getOther() {
      const main = this.product.productMainImage;
      for (const index in this.product.images) {
        if (this.product.images[index] !== main) {
          return this.product.images[index];
        }
      }
      return main;
    },
    getLength() {
      for (const index in this.product.productKenmerken) {
        if (this.product.productKenmerken[index][0] === "Minimum steellengte") {
          this.length = this.product.productKenmerken[index][1];
        }
      }
    },
  },
})
export default class ProductItem extends Vue {}
