export class Product {
  title = "";
  productName = "";

  static translateName(
    productName: string,
    translations: Record<string, string>
  ) {
    productName = productName.toLowerCase();
    for (const key in translations) {
      productName = productName.replace(key, translations[key] as string);
    }
    return productName;
  }
}
