
import { Vue, Options } from "vue-class-component";

@Options({
  name: "HomeTestimonials",
  created() {
    setTimeout(() => {
      if (this.$refs.carousel) {
        this.carouselWidth =
          this.$refs.carousel.clientWidth * this.testimonials.length;
      }
    }, 100);
  },
  data() {
    return {
      testimonials: [
        {
          text: this.$t("home.testimonials.text1"),
          subText: this.$t("home.testimonials.subText1"),
          name: this.$t("home.testimonials.name1"),
          image: require("@/assets/images/testimonials/roses-home-2.png"),
        },
        {
          text: this.$t("home.testimonials.text2"),
          subText: this.$t("home.testimonials.subText2"),
          name: this.$t("home.testimonials.name2"),
          image: require("@/assets/images/testimonials/roses-home.png"),
        },
      ],
      page: 0,
      carouselWidth: null,
    };
  },
  methods: {
    move(inc: number) {
      this.page = Math.min(
        this.testimonials.length - 1,
        Math.max(this.page + inc, 0)
      );
    },
  },
})
export default class HomeTestimonials extends Vue {}
