import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/open.png'
import _imports_1 from '@/assets/images/close.png'


const _withScopeId = (n: any) => (_pushScopeId("data-v-12d7e62a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "faq" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "heading" }
const _hoisted_4 = { class: "faqList" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "faqTitle" }
const _hoisted_7 = { class: "faqIcon" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "faqButton" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("home.faqs.title")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faqs, (faq, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "faqItem",
            key: index
          }, [
            _createElementVNode("div", {
              class: "faqHeader",
              onClick: ($event: any) => (faq.open = !faq.open)
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h3", null, _toDisplayString(faq.faqName), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("img", {
                  src: _imports_0,
                  class: _normalizeClass([!faq.open ? 'show' : ''])
                }, null, 2),
                _createElementVNode("img", {
                  src: _imports_1,
                  class: _normalizeClass([faq.open ? 'show' : ''])
                }, null, 2)
              ])
            ], 8, _hoisted_5),
            _createElementVNode("div", {
              class: _normalizeClass(['faqBody', faq.open ? 'show' : ''])
            }, [
              _createElementVNode("div", {
                class: "faqContent",
                innerHTML: faq.faqText
              }, null, 8, _hoisted_8)
            ], 2)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_router_link, {
          to: "/faq",
          class: "btn faqBtn"
        }, {
          default: _withCtx(() => [
            _createTextVNode("View All")
          ]),
          _: 1
        })
      ])
    ])
  ]))
}