<template>
  <div class="home-header">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-7 col-md-12 d-flex flex-column justify-content-center"
        >
          <h1>{{ $t("home.title") }}</h1>
          <p class="pre-wrap">
            {{ $t("home.description") }}
          </p>
          <button class="btn">{{ $t("buttons.learnMore") }}</button>
        </div>

        <div class="col-lg-5 col-md-12 part2">
          <img class="w-100" src="@/assets/images/home-img.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeHeader",
};
</script>

<style scoped lang="scss">
.home-header {
  background-color: rgba(241, 101, 42, 0.05);
}
@media only screen and (max-width: 576px) {
  .home-header {
    padding: 50px 0 0 0;
    .col-lg-7 {
      padding-right: 15px !important;
    }
    h1 {
      margin-bottom: 0px;
    }
    p {
      font-size: 14px;
    }
    .part2 img {
      height: auto !important;
      object-fit: cover !important;
      margin-top: 15px;
    }
  }
}
</style>
