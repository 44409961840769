
import { Options, Vue } from "vue-class-component";
import HomeHeader from "@/components/Home/Header.vue";
import HomeVideo from "@/components/Home/HeroVideo.vue";
import HomePrograms from "@/components/Home/Programs.vue";
import HomeShop from "@/components/Home/Shop.vue";
import HomeAbout from "@/components/Home/HomeAbout.vue";
import HomeTrends from "@/components/Home/HomeTrends.vue";
import HowItWorks from "@/components/Home/HowItWorks.vue";
import HomeTestimonials from "@/components/Home/HomeTestimonials.vue";
import FAQ from "@/components/Home/FAQ.vue";

@Options({
  components: {
    HomeHeader,
    HomeVideo,
    HomePrograms,
    HomeShop,
    HomeAbout,
    HomeTrends,
    HowItWorks,
    HomeTestimonials,
    FAQ,
  },
  created() {
    document.title = "DutchFlowers";
  },
})
export default class HomeView extends Vue {}
